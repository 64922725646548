var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center" },
        [
          _c("h3", [_vm._v("Select Program")]),
          _c(
            "v-chip",
            {
              staticClass: "ml-2 text-uppercase",
              attrs: { small: "", color: "error darken-2", dark: "" }
            },
            [_vm._v("Test Only")]
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-3" }),
      _c("psi-slide-selector", {
        attrs: {
          items: _vm.programs,
          "max-width": 800,
          "active-color": "primary",
          "content-width": 400,
          "content-height": 200,
          mandatory: false
        },
        on: {
          "slide-selector:change": function($event) {
            return _vm.$emit("program-select", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              var active = ref.active
              return [
                _c("h4", [_vm._v(_vm._s(item.name))]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center mt-2" },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", class: { "white--text": active } },
                      [_vm._v("mdi-map-marker")]
                    ),
                    _c("div", [
                      _c("h5", { staticClass: "font-weight-light" }, [
                        _vm._v(" " + _vm._s(item.address.street) + " ")
                      ]),
                      _c("h5", { staticClass: "font-weight-light" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.address.city) +
                            ", " +
                            _vm._s(item.address.state) +
                            " " +
                            _vm._s(item.address.zip) +
                            " "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "subitem",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("h5", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Sessions:")
                      ]),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ml-2",
                          attrs: { small: "", color: "grey lighten-2" }
                        },
                        [_vm._v(_vm._s(item.sessions.length))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mt-2" },
                    [
                      _c("h5", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Instructors:")
                      ]),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ml-2",
                          attrs: { small: "", color: "grey lighten-2" }
                        },
                        [_vm._v(_vm._s(item.instructors.length))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mt-2" },
                    [
                      _c("h5", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Students:")
                      ]),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ml-2",
                          attrs: { small: "", color: "grey lighten-2" }
                        },
                        [_vm._v(_vm._s(item.students.length))]
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }