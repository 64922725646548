<template>
    <div>
        <div class="d-flex align-center justify-center">
            <h3>Select Program</h3>
            <v-chip
                small
                color="error darken-2"
                dark
                class="ml-2 text-uppercase"
                >Test Only</v-chip
            >
        </div>
        <v-divider class="my-3"></v-divider>
        <psi-slide-selector
            :items="programs"
            :max-width="800"
            active-color="primary"
            :content-width="400"
            :content-height="200"
            :mandatory="false"
            @slide-selector:change="$emit('program-select', $event)"
        >
            <template v-slot:item="{ item, active }">
                <h4>{{ item.name }}</h4>
                <div class="d-flex align-center mt-2">
                    <v-icon :class="{ 'white--text': active }" class="mr-2"
                        >mdi-map-marker</v-icon
                    >
                    <div>
                        <h5 class="font-weight-light">
                            {{ item.address.street }}
                        </h5>
                        <h5 class="font-weight-light">
                            {{ item.address.city }},
                            {{ item.address.state }}
                            {{ item.address.zip }}
                        </h5>
                    </div>
                </div>
            </template>
            <template v-slot:subitem="{ item }">
                <div>
                    <div class="d-flex align-center">
                        <h5 style="min-width: 100px">Sessions:</h5>
                        <v-chip small color="grey lighten-2" class="ml-2">{{
                            item.sessions.length
                        }}</v-chip>
                    </div>
                    <div class="d-flex align-center mt-2">
                        <h5 style="min-width: 100px">Instructors:</h5>
                        <v-chip small color="grey lighten-2" class="ml-2">{{
                            item.instructors.length
                        }}</v-chip>
                    </div>
                    <div class="d-flex align-center mt-2">
                        <h5 style="min-width: 100px">Students:</h5>
                        <v-chip small color="grey lighten-2" class="ml-2">{{
                            item.students.length
                        }}</v-chip>
                    </div>
                </div>
            </template>
        </psi-slide-selector>
    </div>
</template>
<script>
export default {
    name: "program-select",
    components: {},
    props: {
        programs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>